import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import post from '../params/post'
import '../style/style.css'
import '../style/sty.scss'
import '../style/gold3.css'

import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { Timer } from '../components/timer'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = 'rgb(57, 62, 49)'
let black = 'rgb(38,38,38)'

let id = 'aini-panji'
let id_temp = 'reni-shidqi'
let inisial_co = 'PANJI'
let inisial_ce = 'AINI'

let lengkap_co = (<>Gumilar Panji Fitriajie, S.H. </>)
let lengkap_ce = (<>Nur’aini Jamilatul Badriyah, dr. </>)

let bapak_co = 'Henhen Hendaya '
let ibu_co = 'Cucu Juarsih, S.H.'
let bapak_ce = "Dr. dr. H. Dzulfikar DLH, SpA(K), M.Kes, MMRS "
let ibu_ce = "dr. Hj. Ani Supriyatni, SpOG"

let ig_co = "gmlrpanji"
let ig_ce = "nurainijb"

let foto_ce = pw(id_temp, "ce.jpg")
let foto_co = pw(id_temp, "co.jpg")
let waktunikah = "2021-01-03T14:30:00.377"

let modal = pw(id, "Modal.jpg")
let openlogo = pw(id, "Logo 2.png")
let logoig = pw("asset", "logoig-green.svg")
let gmaps = "https://g.page/intercontinentalbandung?share"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NmkyaDVrY3B2NXBna2R0bGJudm4wZ2hnZzkgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.198325604647!2d107.63975951477259!3d-6.8668220950377075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e715a13ee277%3A0x5376a74da04505a1!2sInterContinental%20Bandung%20Dago%20Pakar!5e0!3m2!1sid!2sid!4v1607504988793!5m2!1sid!2sid"
let slide = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
]

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.nama1 = React.createRef()
        this.nama2 = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        let query = this.useQuery().get('u');
        let cd = this.useQuery().get('cd');
        if(cd=="xcv"){
            cd=1
            this.setState({hadir:true})
        }else if (cd=="cvb"){
            cd=2
            this.setState({hadir:true})
        }else {
            cd=0
            this.setState({hadir:''})

        }

        query = query ? cap(query) : ''
        this.setState({query:query,cd:cd})
    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        let tamu1 =""
        let tamu2 =""
    
        if(this.nama2.current.value==''){
            tamu2="-"
        }else {
            tamu2=this.nama2.current.value
        }
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            this.setState({pesan:'-'})
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)} & ${encodeURI(tamu2)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, query,cd } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id_temp, v + ".jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
       

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: '#E0EBE6'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4 p-md-2'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        {query ? (<> Kepada Yth :<br />{query}</>) : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container fluid style={{backgroundColor:'white'}}>
                                    <Item>
                                        <img src={pw(id,"logo-ap.png")} style={{width:'100px',height:'100px'}}/>
                                    </Item>
                                </Container>
                                <Container fluid className="dinny px-3 pt-5" style={{ backgroundColor: 'white' }}>
                                    <Item>

                                        <p className="fs16 text-center cblack px-3" style={{ fontFamily: 'Tinos, serif' }}>
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)
                        
                                    </p>
                                    </Item>
                                   
                                </Container>
                                

                                <Container fluid style={{ backgroundColor: '#393F33' }}
                                    className="py-3">

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'white', fontFamily: 'Tinos, serif' }}>
                                            THE INTIMATE WEDDING OF <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>

                                </Container>
                                <Container fluid style={{ fontFamily: 'Tinos, serif' }}>
                                    <Row>
                                        <Col xs={12} md={6} style={{ backgroundColor: 'white', coor: '#4E4F4B' }} className="py-3">
                                            <Item>
                                                <h1 style={{ fontSize: '72px' }}>
                                                    {inisial_ce}
                                                </h1>
                                            </Item>
                                            <Item>
                                                <Col xs={6} md={4}>
                                                    <img src={pw(id,"ce.svg")} className="w-100 img-fluid"/>
                                                </Col>
                                            </Item>

                                            <Item>
                                                <h1 className="py-3 w-100 text-center"
                                                    style={{ fontSize: '32px', }}>
                                                    {lengkap_ce}
                                                </h1>
                                            </Item>
                                            <Item>
                                                <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                    <b>Putri dari :</b><br />
                                                    {bapak_ce}  <br />
                                                        &<br />
                                                    {ibu_ce}
                                                </p>
                                            </Item>
                                            <Item>
                                                <img src={logoig} className='btn p-0'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                            </Item>
                                        </Col>
                                        <Col xs={12} md={6} style={{ backgroundColor: '#88573F', color: 'white' }} className="py-3">
                                            <Item>
                                                <h1 style={{ fontSize: '72px' }}>
                                                    {inisial_co}
                                                </h1>
                                            </Item>
                                            <Item>
                                                <Col xs={6} md={4}>
                                                    <img src={pw(id,"co.svg")} className="w-100 img-fluid"/>
                                                </Col>
                                            </Item>

                                            <Item>
                                                <h1 className="py-3 w-100 text-center" style={{
                                                    fontSize: '32px',

                                                }}>
                                                    {lengkap_co}
                                                </h1>
                                            </Item>
                                            <Item>
                                                <p className='text-center' style={{ fontSize: '20px' }}>
                                                    <b>Putra dari:</b><br />
                                                    {bapak_co}
                                                    <br />
                                                        &<br />
                                                    {ibu_co}
                                                </p>
                                            </Item>
                                            <Item>
                                                <img src={pw("asset", "logoig-white.svg")} className='btn p-0'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_co}`) }}
                                                    width="35px" height="35px" />
                                            </Item>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container fluid style={{ backgroundColor: '#393E31' ,
                                fontFamily: 'Tinos, serif',color:'white'}} 
                                className="text-center py-3">
                                    <Item>
                                        <p className="fs16">
                                            Yang akan dilaksanakan pada:
                                        </p>
                                    </Item>
                                    <Item >
                                    <table style={{transform:'scale(1.3)'}} className="py-3">
  <tbody>
    <tr>
      
      <td class="align-middle px-1 px-md-3">
      <p className="fs20 w-100 text-right mb-0 text-center">
                                            <b>
                                                MINGGU
                                            </b><br/>
                                            <b>
                                                03 . 01 . 21
                                            </b>
                                            </p>
      </td>
      <td class="align-middle px-1 px-md-3" style={{width:'2px'}}>
          <div style={{height:'100%', borderRight:'2px solid white',width:'2px'}}>
          </div>
      </td>
      <td class="align-middle px-3">
      <p className="fs20 w-100 text-left mb-0">
                                            <b>
                                                15.00 WIB 
                                            </b>
                                            </p>
      </td>
      
    </tr>
  </tbody>
</table>
                                       

                                    </Item>
                                    
                                    <Item>
                                        <p className="fs16 pt-5">
                                            <b>The Diamond Intercontinental Hotel Bandung 
                                                


                                            <br />
                                            Jalan Resor Dago Pakar Raya 2B, Kota Bandung, Jawa Barat, 40191
                                            </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px',
                                                backgroundColor:'white'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src={pw("asset","maps-green.svg")} className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{color:'#393E31'}}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px',
                                                backgroundColor:'white'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src={pw("asset","calendar-green.svg")} className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{color:'#393E31'}}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>


                                <Container fluid className="text-center px-4 dinny" style={{ color: black,backgroundColor:'white' }} >
                                <Timer cmain={cmain} waktunikah={waktunikah} />

<Container className="py-3">
    <Item>
        <Col xs={12} md={6}>
            <img src={pw("asset", "covid-green.svg")} className="w-100 img-fluid" />
        </Col>
    </Item>
</Container>
<Container id='sectiongold56'>
                                    <div className='py-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='col-10' style={{ color:black,backgroundColor:cmain}} >
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            “Aku ingin mencintaimu dengan sederhana,<br/>
                                                            dengan kata yang tak sempat diucapkan<br/>
                                                            kayu kepada api yang menjadikannya abu.<br/>
                                                            Aku ingin mencintaimu dengan sederhana,<br/>
dengan isyarat yang tak sempat disampaikan<br/>
awan kepada hujan yang menjadikannya tiada."<br/><br/>
(Sapardi Djoko Damono)
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>


                                </Container>
                               <div style={{backgroundColor:'rgb(136, 87, 63)'}}>

                                
                                {/* <Container id='sectiongold58' >

                                    <div className='pt-3 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: 'Tinos,serif',
                                                        color: cmain
                                                    }}>
                                                        RSVP
                                                         </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Tamu 1" name='nama' />
                                                        <input ref={this.nama1} type={cd==1||cd==2?'text':'hidden'} className="col-12 w-100 text-center" placeholder="Tamu 1" name='nama1'  />
                                                        <input ref={this.nama2} type={cd==2?'text':'hidden'} className="col-12 w-100 text-center" placeholder="Tamu 2" name='nama2' />

                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        {cd==1||cd==2?(
                                                            <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item>
                                                        ):false}
                                                       
                                                          
                                    

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                             onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container> */}
                                 <Item>
                                    <Container style={{border:'2px solid white'}} className="p-3 m-3">
                                <p className="fs16 text-center w-100 cblack px-3" 
                                style={{ fontFamily: 'Tinos, serif',color:'white',fontSize:'16px' }}>
                                       <b> Mengingat situasi pandemi covid-19 yang belum mereda, kami mohon maaf tidak dapat mengundang Bapak/Ibu/Saudara/i untuk hadir pada acara kami secara langsung. Tanpa mengurangi rasa hormat, Bapak/Ibu/Saudara/i dapat mengikuti acara akad nikah kami secara virtual melalui jaringan Zoom, Youtube, atau Instagram berikut ini:
                                       </b></p>
                                <div className="py-2">
                                <Item>
                                        <div className="p-1 btn mx-1" onClick={()=>{
                                            window.location.href='https://us02web.zoom.us/j/86286578580?pwd=eXl4Smo0L2w4dklGbzV5elMyVUNlZz09'
                                        }} 
                                        style={{
                                            border:`2px solid white`
                                        }}>
                                            <img src={pw(id,"zoom.png")} style={{width:'50px', height:'50px'}}/>
                                        </div>
                                        <div className="p-1 btn mx-1" onClick={()=>{
                                            window.location.href='https://youtu.be/E6zVWkCvMFs'
                                        }} 
                                        style={{
                                            border:`2px solid white`
                                        }}>
                                            <img src={pw(id,"youtube.png")} style={{width:'50px', height:'50px'}}/>
                                        </div>
                                        <div className="p-1 btn mx-1" onClick={()=>{
                                            window.location.href='https://www.instagram.com/gmlrpanji/'
                                        }} 
                                        style={{
                                            border:`2px solid white`
                                        }}>
                                            <img src={pw(id,"instagram.png")} style={{width:'50px', height:'50px'}}/>
                                        </div>
                                    </Item>
                                </div>

                                
                                </Container>

                                    </Item>
                               

                                <Foot ig={pw("asset","logoig-white.svg")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

